<script>
export default {
  name: 'DownloadApp',
  mounted () {
    this.detectOSAndRedirect()
  },
  methods: {
    detectOSAndRedirect() {
      const ua = navigator.userAgent
      if (/android/i.test(ua)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.arkinfosoft.primelaundry'
        return
      }
      else if (
        /iPad|iPhone|iPod/.test(ua) || 
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        window.location.href = 'https://apps.apple.com/us/app/primelaundry/id1556047501#?platform=iphone'
        return  
      }
      this.$router.push({ name: 'BookNow' })

    }
  }
}
</script>